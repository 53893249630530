<template>
  <div>
    <div class='hot-content' v-for='(item,index) in hotDataInfo.list' :key='item.id' @click='gotoDetail(item.id)'>
      <div :class="['hot-left',{'hot-num1': index === 0},{'hot-num2': index === 1},{'hot-num3': index === 2}]">
        <span v-show='index > 2'>{{ index > 8 ? index + 1 : '0' + (index + 1) }}</span>
      </div>
      <div class='hot-right'>
        <div class='hot-title'>{{item.title}}</div>
        <div class='hot-author'>
          <span class='nicname'>{{ item.author.nickname }}</span>
          <span class='redu'>{{ item.views | temperature }}</span>
        </div>
      </div>
    </div>
    <div class='hot-more' v-if='hotDataInfo.hasMore' @click='getHotListMore'>点击加载更多<p><span class="iconfont ico">&#xe65d;</span></p></div>
  </div>
</template>

<script>
export default {
  name: 'HotList',
  props: {
    hotDataInfo: Object,
    hotFlag: Number,
    index: Number
  },
  data() {
    return {
      hidemore: true
    }
  },
  filters: {
    temperature(args) {
      if (args === 10000) {
        return '1万热度'
      } else {
        let hot = Math.round((args / 10000))
        return hot + '万热度'
      }

    }
  },
  methods: {
    async getHotListMore() {
      let {data: res} = await this.$api.queryHotChartListMore(`?lastId=${this.hotDataInfo.list[4].id}&tagId=${this.hotDataInfo.tagId}&type=${this.hotFlag}`)
      let timer = null
      if (res.code === 200 && this.hidemore) {
        this.hidemore = false
        this.$emit('changeHotList',res.data.data,this.index)
        timer = setTimeout(() => {
          this.hidemore = true
        },500)
      } else {
        this.hidemore = true
        clearTimeout(timer)
      }
    },
    gotoDetail(id) {
      this.$router.push({
        path: '/note/' + id,
      })
    }
  }
}
</script>

<style scoped lang='less'>
.hot-content {
  display: flex;
  width: 100%;
  height: 54px;
  margin-bottom: 16px;

  .hot-left {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    span {
      font-size: 17px;
      font-weight: 500;
      text-align: left;
      vertical-align: top;
      color: #9da4b1;
      letter-spacing: 0px;
    }
  }

  .hot-right {
    width: 325px;
    height: 100%;
    border-bottom: 1px solid #F0EDED;

    .hot-title {
      width: 100%;
      height: 27px;
      line-height: 22px;
      font-size: 17px;
      font-weight: 400;
      text-align: left;
      color: #111111;
      letter-spacing: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .hot-author {
      width: 100%;
      line-height: 16px;

      .nicname {
        font-size: 11px;
        color: #8C94A3;
      }

      .redu {
        margin-left: 9px;
        font-size: 11px;
        color: #8C94A3;
      }
    }
  }

  .hot-num1 {
    width: 24px;
    height: 24px;
    margin: 0px 2px 0px -4px;
    background: url("https://cdn.9kd.com/kdnet/hot_1.png") no-repeat;
    background-size: contain;
    background-size: 110%;
  }

  .hot-num2 {
    width: 24px;
    height: 24px;
    margin: 0px 2px 0px -4px;
    background: url("https://cdn.9kd.com/kdnet/hot_2.png") no-repeat left center;
    background-size: cover;
    background-size: 110%;
  }

  .hot-num3 {
    width: 24px;
    height: 24px;
    margin: 0px 2px 0px -4px;
    background: url("https://cdn.9kd.com/kdnet/hot_3.png") no-repeat left center;
    background-size: cover;
    background-size: 110%;
  }
}
.hot-more {
  display: flex;
  justify-content: center;
  margin-bottom: 23px;
  height: 20px;
  font-size: 14px;
  color: #666666;
  p {
    margin-left: 8px;
  }
}
</style>
