<template>
  <div class="brand-container">
    <div>
      <img src="https://cdn.9kd.com/kdnet/brand/banner-wap.png" class="banner" />
    </div>
    <van-sticky offset-top="42px">
      <van-tabs class="tab-wrap" swipe-threshold="3" line-height="0" v-model="activeName">
        <van-tab v-for="item in categoryList" :key="index" :name="item.id">
          <template #title>
            <div class="tab" :class="activeName === item.id ? 'active' : ''" @click="clickTab(item.id)">
              <img :src="activeName === item.id ? item.selectedIcon : item.icon" class="tab-icon" />
              <div class="name">{{ item.categoryName }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>
    <div class="table-wrap" id="target-element">
      <table>
        <tr class="theader">
          <th>排名</th>
          <th>品牌</th>
          <th class="flex-x-center-center"><span>舆情健康指数</span> <img src="https://cdn.9kd.com/kdnet/brand/icon-tips.png" style="width: 20px" @click="openSheet" /></th>
        </tr>
        <tr v-for="(item, index) in tableData" :key="index" class="t-content">
          <td>
            <img :src="medalMap.get(item.ranking)" class="index-icon" v-if="item.ranking < 4" /> <span v-else>{{ item.ranking }}</span>
          </td>
          <td>{{ item.brandName }}</td>
          <td>
            <img :src="indexIcon.get(item.brandIndexOne)" class="index-icon" />
            <img :src="indexIcon.get(item.brandIndexTwo)" class="index-icon" />
            <img :src="indexIcon.get(item.brandIndexThree)" class="index-icon" />
            <img :src="indexIcon.get(item.brandIndexFour)" class="index-icon" />
            <img :src="indexIcon.get(item.brandIndexFive)" class="index-icon" />
          </td>
        </tr>
      </table>
    </div>

    <div class="description">
      <div class="title">品牌舆情健康指数价值说明</div>
      <div class="card-wrap">
        <div class="card" v-for="(item, index) in descriptionList" :key="index" :style="{ 'background-image': `url(${item.bg})` }">
          {{ item.content }}
        </div>
      </div>
    </div>
    <div class="index-footer">
      <div>品牌指数需求请联系</div>
      <div class="contact">
        <div>电话：4009989020转1</div>
        <div>邮箱：yunshanghui@9kd.com</div>
      </div>
    </div>
    <van-action-sheet v-model="show" title="说明">
      <div class="content">
        <div>
          说明：该指数为凯迪网原创指数，从品牌、用户和影响着的角度出发，深度考察品牌在线内容的传播力、与用户的交互质量、舆情响应效能等核心在线行为，进一步分析品牌在主要社交平台上的传播力、舆情管理水平和与不同用户群体的精准契合度，以综合评估品牌舆情的整体健康状况。不仅为品牌提供了清晰、系统的健康发展诊断及舆情表现分析，更为品牌战略制定提供了有力的数据支撑与策略指导，旨在推动品牌价值的持续升级，助力品牌走向更为稳健、持久的发展之路<br />*图中健康度太阳>月亮>星星，排列组合之后的等级，对应指数标准化结果。
        </div>
        <div class="text-center"><van-button round style="width: 192px; margin-top: 21px" @click="show = false">我知道了</van-button></div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 1,
      indexIcon: new Map([
        [1, 'https://cdn.9kd.com/kdnet/brand/icon-star.png'],
        [2, 'https://cdn.9kd.com/kdnet/brand/icon-moon.png'],
        [3, 'https://cdn.9kd.com/kdnet/brand/icon-sun.png'],
      ]),
      medalMap: new Map([
        [1, 'https://cdn.9kd.com/kdnet/brand/icon-1.png'],
        [2, 'https://cdn.9kd.com/kdnet/brand/cache/icon-silver.png'],
        [3, 'https://cdn.9kd.com/kdnet/brand/icon-3.png'],
      ]),
      descriptionList: [
        {
          bg: 'https://cdn.9kd.com/kdnet/brand/bg-wap-test.png',
          content: '定期评估品牌舆论健康度，检测品牌线上的传播力、与用户的交互质量、舆情管理水平等',
        },
        {
          bg: 'https://cdn.9kd.com/kdnet/brand/bg-wap-fame.png',
          content: '通过深入分析用户行为，协助品牌调整市场策略，加强品牌的正向口碑，进而提高品牌美誉度和用户忠诚度',
        },
        {
          bg: 'https://cdn.9kd.com/kdnet/brand/bg-wap-opinion.png',
          content: '根据舆情管理能力，为品牌提供预警机制，预防或迅速应对可能的品牌危机，降低风险',
        },
        {
          bg: 'https://cdn.9kd.com/kdnet/brand/bg-wap-increase.png',
          content: '通过系统性地分析舆论数据，帮助品牌更好地了解市场需求，挖掘市场情报、发现增长机会',
        },
      ],
      tableData: [],
      categoryList: [],
      show: false,
    }
  },
  mounted() {
    this.getTab()
  },
  methods: {
    openSheet() {
      this.show = true
    },
    clickTab(tab) {
      this.activeName = tab
      this.getList()
      this.scrollToTable()
    },
    scrollToTable() {
      const element = document.getElementById('target-element')
      const top = element.getBoundingClientRect().top
      if (element && top < 10) {
        window.scrollTo({
          top: element.offsetTop - 88,
          behavior: 'smooth', // 平滑滚动效果
        })
      }
    },
    async getList() {
      try {
        const {
          data: { data },
        } = await this.$api.getBandIndexListByCategoryId(this.activeName)
        this.tableData = data
      } catch (error) {}
    },
    async getTab() {
      try {
        const {
          data: { data },
        } = await this.$api.getBandIndexCategoryList()
        this.categoryList = data
        this.activeName = data[0]?.id
        this.getList()
      } catch (error) {}
    },
  },
}
</script>
<style scope lang="less">
.brand-container {
  min-height: 100vh;
  background: #f7f7f7;
  padding-bottom: 60px;
  max-width: 100%;
}
.banner {
  width: 100%;
  margin-bottom: -6px;
}
.tab-wrap {
  background: #fff;
  width: 100%;
  .tab-icon {
    width: 22px;
    margin-right: 4px;
  }
  .tab {
    flex-shrink: 0;
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0 16px;
    .name {
      flex-shrink: 0;
    }
    &.active {
      background: #ee0a24;
      border-radius: 16px;
      color: #fff;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.table-wrap {
  width: 100%;
  margin-top: 2px;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .theader {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 54px;
    text-align: center;
    position: sticky;
    top: 80px;
    background-color: #fff;
    th {
      width: 25%;
      &:last-child {
        width: 100%;
      }
    }
  }
  .t-content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 60px;
    text-align: center;
    td {
      margin: 0;
      border: none;
    }
    &:nth-child(2n) {
      background: #fff9f6;
    }
  }
}
.description {
  margin: 20px 0;
  .title {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
  }
  .card-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 8px;
  }
  .card {
    width: 175px;
    height: 207px;
    border-radius: 12px;
    background-size: 100% 100%;
    padding: 70px 10px 0px;
    text-align: left;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    box-sizing: border-box;
    &:nth-child(3),
    &:nth-child(4) {
      height: 183px;
    }
  }
}
.index-footer {
  background: #fff;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 25px;
  padding: 16px 0 10px;
  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    height: 22px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    margin: 10px auto 0;
  }
}
.index-icon {
  width: 18px;
}
.flex-x-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 324px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin: 0 auto 12px;
  .text-center {
    text-align: center;
  }
}
.brand-container {
  .van-tabs__wrap {
    padding-right: 0 !important;
  }
  .van-tabs__wrap--scrollable .van-tab {
    padding: 0;
  }
}
</style>
