<template>
  <van-pull-refresh
    v-model="isRefreshLoading"
    :success-text="refreshSuccessText"
    :success-duration="1500"
    @refresh="onRefresh"
  >
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <DynamicCard
        v-for="(list,i) in lists"
        :index="i"
        :list="list"
        :key="list.id"
        @toDetail="goDetail"
      />
    </van-list>
  </van-pull-refresh>
</template>

<script>
import DynamicCard from '@/views/chamber/components/DynamicCard'

export default {
  components: { DynamicCard },
  data() {
    return {
      isIos: false, // 默认不是苹果手机
      lists: [],
      loading: false,
      finished: false,
      searchInfo: {
        limit: 10,
        page: 1
      },
      isRefreshLoading: false, // 下拉刷新的 loading 状态
      refreshSuccessText: '', // 下拉刷新成功的提示文本
    }
  },
  created() {
    this.isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  methods: {
    async onLoad() {
      const params = {
        pageSize: this.searchInfo.limit,
        page: this.searchInfo.page
      }
      let { data: res } = await this.$api.queryPageArticle(params)
      // 加载状态结束
      this.loading = false

      if (res.code !== 200) return this.$toast.fail(res.msg)
      if (this.isIos) {
        res.data.records = this.formatImgForWebp(res.data.records)
      }

      if (res.data.total) {
        this.lists = this.lists.concat(res.data.records)
        this.searchInfo.page++
      }
      if (res.data.total < params.pageSize * params.page) {
        this.finished = true
        this.$toast('数据已到底')
      }
    },

    // 刷新
    async onRefresh() {
      this.finished = false
      this.loading = false
      const params = {
        pageSize: this.searchInfo.limit,
        page: this.searchInfo.page
      }
      params.page = 1
      // 下拉刷新，组件自己就会展示 loading 状态
      let { data: res } = await this.$api.queryPageArticle(params)
      this.isRefreshLoading = false

      if (res.code !== 200) return this.$toast.fail(res.msg)
      if (this.isIos) {
        res.data.records = this.formatImgForWebp(res.data.records)
      }

      if (res.data.total) {
        this.lists = res.data.records
        this.searchInfo.page = 2
      }
      if (res.data.total < params.pageSize) {
        this.refreshSuccessText = '当前已是最新内容'
      } else {
        this.refreshSuccessText = res.data.msg
      }
    },

    goDetail(id) {
      this.$router.push({
        name: 'articleDetail',
        params: { id },
        query: { type: 1 }
      })
    },

    // 解决苹果端不支持webp格式的图片的临时解决方案
    formatImgForWebp(mydata) {
      mydata.forEach(item => {
        item.coverImg = item.coverImg?.includes('.gif') ? item.coverImg : item.coverImg + '?x-oss-process=image/format,jpg'
      })
      return mydata
    }
  }
}
</script>
<style lang='less' scoped>
</style>
