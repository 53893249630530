<template>
  <div class="artical-container" ref="article-list">
    <!-- 频道轮播图 -->
    <div class='channel-carousel' v-if='carouselItem.length'>
      <van-swipe class="my-swipe" :autoplay="3000" :style="{'width':swipeWidth}" indicator-color="white" @change="onSwipeChange">
        <van-swipe-item v-for='item in carouselItem' :key='item.id' @click='openURL(item.articleId,item.url)' class="pos-relative" ref="loopItemSwipe" >
          <div :ref="item.isAdv ? 'advListItem': null" v-bind:id="item.isAdv ? item.onlyId : null" class="h_100">
            <img :src='item.picture' alt>
            <div class="swipe-adv-tip" v-if="item.isAdv">广告</div>
          </div>

          <div class='imgExplain'>
            <p>{{ item.title }}</p>
          </div>

        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 热闻+热帖的切换按钮固定 -->
    <div v-if='this.id === "00"' :class='["hot-btn",{"hot-position":newsAndnote}]'>
      <div class='hot-news' :style="{ background: 'url(' + hotNewsImg + ') no-repeat center center' }" @click='changeHotImg(1)'></div>
      <div class='hot-note' :style="{ background: 'url(' + hotNoteImg + ') no-repeat center center' }" @click='changeHotImg(2)'></div>
    </div>
    <van-pull-refresh v-model="isRefreshLoading" :success-text="refreshSuccessText" :success-duration="1500" @refresh="onRefresh">
      <van-list v-if='this.id !== "00"' v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <!--   置顶     -->
        <TopArticle v-if="isRecommendTab && tops.length > 0" :tops="tops"></TopArticle>
        <!--   社区外其他频道的内容     -->
        <div v-if="id == videoChannelId">
          <video-waterfall :lists="lists" ref="videoFall" @toDetail="goDetail"></video-waterfall>
        </div>
        <template v-else-if="isRecommendTab">
          <CommonCard @click.native="onCardClick(list)"  v-for="(list,i) in lists" :index='i' :channelID='id' :list="list" :key="list.id" @toDetail="goDetail"></CommonCard>
        </template>
        <template v-else>
          <CommonCard v-for="(list,i) in lists" :index='i' :channelID='id' :list="list" :key="list.id" @toDetail="goDetail"></CommonCard>
        </template>
      </van-list>
      <hot-news-note v-if='hotDataInfo.length > 0' :hotDataInfo='hotDataInfo' :hotFlag='hotFlag' @changeHotList='changeHotList'></hot-news-note>
    </van-pull-refresh>
  </div>
</template>

<script>
  import VideoWaterfall from '../../components/VideoWaterfall.vue'
  export default {
    data() {
      return {
        isIos: false, // 默认不是苹果手机
        newsAndnote: false, // 固定热闻热帖的位置
        hotNewsImg: 'https://cdn.9kd.com/kdnet/hot_news_active.png',
        hotNoteImg: 'https://cdn.9kd.com/kdnet/hot_note.png',
        hotDataInfo: [], // 热闻热帖的数据
        hotFlag: 3, // 3：是热闻；1：是热帖
        carouselItem:[],//频道轮播图
        searchInfo: {
          firstId: 0,
          lastId: 0,
          limit: 10,
          page: 1,
          product: this.id,
          tagId: 0,
          type: 'pgc',
        },
        searchInfo1: {
          firstId: 0,
          lastId: 0,
          limit: 10,
          page: 1,
          product: this.id,
          tagId: 0,
          type: 'pgc',
        },
        lists: [],
        tops: [],
        loading: false,
        finished: false,
        isRefreshLoading: false, // 下拉刷新的 loading 状态
        refreshSuccessText: '', // 下拉刷新成功的提示文本
        articles: [],
        flag: true,
        currentSwipeNum: 0,
        advMap:{},
        toolBarHeight: document.getElementById('tool_bar_height').offsetHeight,
        subHeight: document.getElementById('foot-open-box').offsetHeight,
        swipeWidth: '100%',
        videoChannelId: 10079
      }
    },
    props: ['id'],
    components: {
      CommonCard: () => import('@/views/home/CommonCard'),
      TopArticle: () => import('@/views/home/TopArticle'),
      'hot-news-note': () => import('@/views/home/HotNewsNote'),
      VideoWaterfall
    },
    computed:{
      isRecommendTab(){
        return this.id === 0
      }
    },
    beforeDestroy(){
      window.removeEventListener('scroll',this.scrollHandle);
    },
    created() {
      this.isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if(this.id !== '00') {
        this.queryChannelCarousel()
      } else {
        this.queryHotChartList(3)
      }
      this.getVideoCategory();
    },
    mounted(){
      // 获取导航的高度
      window.addEventListener('scroll',this.scrollHandle);
    },
    methods: {
      onCardClick(data){
      },
      /**
       * 获取视频频道和类目
       */
      async getVideoCategory() {
        let {data:res} = await this.$api.getVideoChannel()
        if(res.code == 200) {
          this.videoChannelId = res.data.id
        }
      },
      // 解决苹果端不支持webp格式的图片的临时解决方案
      formatImgForWebp(mydata) {
        mydata.forEach(item => {
          if (item.pictures.length === 0) return
          if (item.layoutType === 3) {
            item.firstPicture = item.firstPicture.includes('.gif') ? item.firstPicture : item.firstPicture + '?x-oss-process=image/format,jpg'
          } else {
            for (let i = 0; i<item.pictures.length;i++) {
              item.pictures[i] = item.pictures[i].includes('.gif') ? item.pictures[i] : item.pictures[i] + '?x-oss-process=image/format,jpg'
            }
          }
        })
        return mydata
      },
      // 热闻、热帖加载更多
      changeHotList(data,index) {
        this.hotDataInfo[index].list = this.hotDataInfo[index].list.concat(data)
        this.hotDataInfo[index].hasMore = false
      },
      // 改变热闻+热帖的内容
      changeHotImg(n) {
        if (n === 1) {
          this.hotNewsImg = 'https://cdn.9kd.com/kdnet/hot_news_active.png'
          this.hotNoteImg = 'https://cdn.9kd.com/kdnet/hot_note.png'
          this.hotFlag = 3
          this.queryHotChartList(3)
          // 切换回到顶部
          document.documentElement.scrollTop = 0
          document.body.scrollTop = 0 // 兼容的写法
        } else {
          this.hotNewsImg = 'https://cdn.9kd.com/kdnet/hot_news.png'
          this.hotNoteImg = 'https://cdn.9kd.com/kdnet/hot_note_active.png'
          this.hotFlag = 1
          this.queryHotChartList(1)
          // 切换回到顶部
          document.documentElement.scrollTop = 0
          document.body.scrollTop = 0 // 兼容的写法
        }
      },
      // 查询热榜首页数据
      async queryHotChartList(type) {
        let { data: res } = await this.$api.queryHotChartList('?type=' + type)
        this.hotDataInfo = res.data
        this.isRefreshLoading = false
      },
      // 查询频道轮播图
      async queryChannelCarousel() {
        let { data: res } = await this.$api.queryChannelViewpager({
          id:this.id,
          media:'WAP'
        })
        if (res.code !== 200) return
        if(res.data && res.data.length){
          for (let i in res.data) {
            if(res.data[i].isAdv){
              res.data[i]['onlyId'] = Math.random().toString().slice(2);
              res.data[i]['indexId'] = i;
              this.advMap[res.data[i]['onlyId']] = res.data[i];
            }
          }
        }
        this.carouselItem = res.data
        this.$nextTick(()=>{
          if(this.carouselItem.length){
            if(this.$refs.loopItemSwipe[0]){
              this.swipeWidth = this.$refs.loopItemSwipe[0].$el.offsetWidth + 'px'
            }

            this.changeCountAdv();
          }
        })
      },
      openURL(id,url){
        if(id!==0){
          this.$router.push('/note/'+id)
        }else{
          window.open(url)
        }
      },
      goDetail(id, articleType, content) {
        if (articleType === 8) {
          // 奥运专题
          this.$router.push({
            name: 'olympic',
            params: {
              id,
              channelId: this.id,
            },
          })
        }else if (articleType === 4) {
          window.open(content)
        } else if (articleType === 5) {
          this.$router.push({
            name: 'special',
            params: {
              id,
              channelId: this.id,
            },
          })
        } else {
          this.$router.push({
            path: '/note/' + id,
            query:this.isRecommendTab ? {ref:encodeURIComponent(`page_id=content_recommend&widget_id=content_recommend_item&res_id=${id}`)} : {}
          })
        }
      },
      async onLoad() {
        let name = this.id == this.videoChannelId ? 'videoList' : 'app'
        if(this.id == this.videoChannelId){
          this.searchInfo.type = 'video'
        }
        let { data: res } = await this.$api[name](this.searchInfo)
        if (this.isRecommendTab) {
        }
        if (res.code === 321) {
          this.finished = true
          return this.$toast(res.msg)
        }
        if (res.code !== 200) return this.$toast.fail(res.msg)
        if (this.isIos) {
          res.data.list = this.formatImgForWebp(res.data.list)
        }
        if(name == 'videoList') {
          this.$refs.videoFall.branchData(false, res.data.list);
        }
        this.lists = this.lists.concat(res.data.list)
        this.tops.length === 0 && (this.tops = res.data.top)
        this.searchInfo.firstId = res.data.firstId
        this.searchInfo.page++
        this.flag && (this.searchInfo1.firstId = res.data.firstId)
        this.flag = false
        this.searchInfo.lastId = res.data.lastId

        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        // this.finished = true;

        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        /* setTimeout(() => {
          for (let i = 0; i < 15; i++) {
            this.list.push(this.list.length + 1);
          }

          // 加载状态结束
          this.loading = false;

          // 数据全部加载完成
          if (this.list.length >= 80) {
            this.finished = true;
          }
        }, 1000); */
      },
      // 刷新
      async onRefresh() {
        this.finished = false;
        this.loading = false;
        if (this.id !== '00') {
          this.searchInfo1.page = 1
          // 下拉刷新，组件自己就会展示 loading 状态
          // 1. 请求获取数据
          /* const { data } = await getArticles({
            channel_id: this.channel.id, // 频道 ID
            timestamp: Date.now(), // 为了大家方便学习，只要你传递不同的时间戳就一定给你返回不一样的数据，而且数据不为空
            with_top: 1
          }) */

          // 2. 把数据放到数据列表中（往顶部追加）
          // const { results } = data.data
          // this.articles.unshift(...results)
          let name = this.id == this.videoChannelId ? 'videoList' : 'app'
          if(this.id == this.videoChannelId){
            this.searchInfo1.type = 'video'
          }
          let { data: res } = await this.$api[name](this.searchInfo1)
          if (res.code === 321) {
            // 3. 关闭刷新的状态 loading
            this.isRefreshLoading = false
            this.refreshSuccessText = '当前已是最新内容'
            return
          }
          if (res.code !== 200) return this.$toast.fail(res.msg)
          if (this.isIos) {
            res.data.list = this.formatImgForWebp(res.data.list)
          }
          if(name == 'videoList') {
            this.$refs.videoFall.branchData(true, res.data.list);
          }
          
          this.lists = res.data.list
          this.tops = res.data.top
          this.searchInfo1.firstId = res.data.firstId
          this.searchInfo.lastId = res.data.lastId
          this.searchInfo.page = 2

          // 3. 关闭刷新的状态 loading
          this.isRefreshLoading = false

          this.refreshSuccessText = res.data.msg

        } else {
          // 获取热榜+热闻
          this.queryHotChartList(this.hotFlag)
        }

      },
      onSwipeChange(index){
        this.currentSwipeNum = index;
        this.changeCountAdv();
      },
      changeCountAdv(){
        var sNum = this.currentSwipeNum;
        // 判断是否为广告
        if(this.carouselItem[sNum]['isAdv']){
          // 1.先用一个id记录当前的广告id
          var id = this.carouselItem[sNum]['onlyId'];
          var current = null;
          for (let item of this.$refs.advListItem) {
            if(this.advMap[id]['onlyId'] == item['id']){
              current = item;
              break;
            }
          }
          // 1. 记录广告的位置信息，包括两个信息，第一个是距离顶部的距离，第二个是广告这个div的高度
          if(!this.advMap[id]['offset']){
            this.advMap[id]['offset'] = {
              offsetTop: current.getBoundingClientRect().top,
              offsetHeight: current.offsetHeight,
              initOffTop: current.getBoundingClientRect().top,
              noNavOffTop: current.getBoundingClientRect().top - this.toolBarHeight
            }
          }
          // 2. 轮播图切换的时候重新给当前的轮播广告记录一个时间（这个是用来滚动使用的）
          this.advMap[id]['time'] = new Date().getTime();



          // 3. 延迟一秒的时间，一秒之后判断是否在可见区域
          setTimeout(() => {
            // 判断没有导航条，距离顶部的距离需要重新计算
            if(window.scrollY >= this.toolBarHeight){
              this.advMap[id]['offset'].offsetTop = this.advMap[id]['offset'].noNavOffTop
            }else{
              this.advMap[id]['offset'].offsetTop = this.advMap[id]['offset'].initOffTop
            }
            let itemOffTop = this.advMap[id]['offset'].offsetTop;
            let itemOffHeight = this.advMap[id]['offset'].offsetHeight;
            // 需要剪掉底部的广告轮播外加只有大于60%显示才算在可见区域（算一次曝光）
            if (itemOffTop >= (window.scrollY - itemOffHeight + (itemOffHeight * 0.6))
            && itemOffTop < (window.scrollY + window.innerHeight - this.subHeight - (itemOffHeight * 0.6))) {

              // 4. 如果在可见区域，需要给当前轮播的广告定义一个num的值，如果有，说明已经曝光了，不需要做任何处理，如果没有接着处理
              // if(!this.advMap[id]['num']){

                // 5. 如果没有num的值，判断当前轮播的广告跟你记录的广告id是否一致，一致就曝光，需要给num+1
                if(this.currentSwipeNum == this.advMap[id]['indexId']){
                  // this.advMap[id]['num'] = 1;
                  this.advExposureAdd(this.advMap[id]);
                  this.advMap[id]['isExposure'] = true
                }
              // }
            }

          }, 1000);
        }
      },
      scrollHandle(){
        // 监控热闻热帖位置切换
        let pageScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        this.newsAndnote = pageScrollTop > 45 ? true : false
        // 轮播图广告计算曝光
        var advList = this.$refs.advListItem;
        this.countExposure(advList, this.subHeight);
      },
      countExposure(advList,subHeight){
        if(advList){
          for (let item of advList) {
            // 先存距离顶部的距离和高度
            if(!this.advMap[item['id']]['offset']){
              this.advMap[item['id']]['offset'] = {
                offsetTop: item.getBoundingClientRect().top,
                offsetHeight: item.offsetHeight,
                initOffTop: item.getBoundingClientRect().top,
                noNavOffTop: item.getBoundingClientRect().top - this.toolBarHeight
              }
            }
            // 判断没有导航条，距离顶部的距离需要重新计算
            if(window.scrollY >= this.toolBarHeight){
              this.advMap[item['id']]['offset'].offsetTop = this.advMap[item['id']]['offset'].noNavOffTop
            }else{
              this.advMap[item['id']]['offset'].offsetTop = this.advMap[item['id']]['offset'].initOffTop
            }
            let itemOffTop = this.advMap[item['id']]['offset'].offsetTop;
            let itemOffHeight = this.advMap[item['id']]['offset'].offsetHeight;
            // 需要剪掉底部的广告轮播外加只有大于60%显示才算在可见区域（算一次曝光）
            if (itemOffTop >= (window.scrollY - itemOffHeight + (itemOffHeight * 0.6))
            && itemOffTop < (window.scrollY + window.innerHeight - subHeight - (itemOffHeight * 0.6))) {

              // 第一次记录时间
              if(!this.advMap[item['id']]['time']){
                this.advMap[item['id']]['time'] = new Date().getTime();
                var itemId = item['id']
                setTimeout(() => {
                  if (itemOffTop >= (window.scrollY - itemOffHeight + (itemOffHeight * 0.6))
                  && itemOffTop < (window.scrollY + window.innerHeight - subHeight - (itemOffHeight * 0.6))) {
                    if(!this.advMap[itemId]['isExposure']){
                      // 记录是否已经曝光
                      // if(!this.advMap[itemId]['num']){
                        if(this.currentSwipeNum == this.advMap[itemId]['indexId']){
                          // 标记曝光
                          // this.advMap[itemId]['num'] = 1;
                          this.advMap[itemId]['isExposure'] = true;
                          this.advExposureAdd(this.advMap[itemId]);
                        }

                      // }

                    }
                  }
                }, 1000);
              }else{
                // 曝光时长大于或等于1秒
                var current = new Date().getTime();
                if(current - this.advMap[item['id']]['time'] >= 1000){
                  if(!this.advMap[item['id']]['isExposure']){
                    // 记录是否已经曝光
                    // if(!this.advMap[item['id']]['num']){
                      if(this.currentSwipeNum == this.advMap[item['id']]['indexId']){
                        // 标记曝光
                        // this.advMap[item['id']]['num'] = 1;
                        this.advMap[item['id']]['isExposure'] = true;
                        this.advExposureAdd(this.advMap[item['id']]);
                      }

                    // }

                  }

                }
              }

            // 不在可见区域的时候需要把时间置为null,是否曝光置为false
            }else{
              this.advMap[item['id']]['time'] = null;
              this.advMap[item['id']]['isExposure'] = false;
            }
          }
        }
      },
      advExposureAdd(item){
        this.$api.queryLoopAdvAddOne({adId:item.id}).then(res=>{}).catch(err=>{})
      }
    },
  }
</script>

<style lang="less">
  /* .van-list {
    min-height: 400px;
  } */
  .artical-container {
    word-wrap: break-word;
    overflow: hidden;
    .channel-carousel{
      width: 94%;
      margin: 14px auto;
      border-radius: 4px;
      overflow: hidden;
    }
    .hot-btn {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 78px;
      background: #fff;
      z-index: 99;
      .hot-news {
        width: 50%;
        background-size: cover !important;
      }
      .hot-note {
        width: 50%;
        background-size: cover !important;
      }
    }
    .hot-position {
      position: fixed;
      top: 44px;
    }
  }
  .van-swipe__indicators{
    position: absolute;
    bottom: 3px;
  }
  .my-swipe{
    width: 100%;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    width: 100%;
    height: 180px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
      display: block;
      object-fit: cover;
    }
    .imgExplain {
      position: absolute;
      width:100%;
      height:38px;
      line-height: 38px;
      bottom: 0;
      left: 0;
      font-size: 17px;
      padding:0 14px;
      background: rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      p{
        text-align: left;
        color: #ffffff;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .pos-relative{
    position: relative;
  }
  .swipe-adv-tip{
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid white;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 11px;
    color: white;
  }
  .h_100{
    height: 100%;
  }

</style>
