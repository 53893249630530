<template>
  <div class='new-community'>
    <!--  社区频道模块  -->
    <van-pull-refresh v-model='isRefreshLoading' :success-text='refreshSuccessText' :success-duration='1500'
                      @refresh='onRefresh'>
      <van-list v-model='loading' :finished='finished' finished-text='没有更多了' @load='onLoad'>
        <div class='commun-wrap' v-for='list in lists' :key='list.id'>
          <div class='commun-item-1'>
            <div class='left'>
              <router-link class='avatar' tag='div' to='/download'
                           :style="list.author.avatar ? { background: 'url(' + list.author.avatar + ') no-repeat center center', 'background-size': 'cover' } : {background: 'url(https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png) no-repeat center center', 'background-size': 'cover'}"></router-link>
              <img v-if='list.author.certify && list.author.field' class='vip-img'
                   src='https://cdn.9kd.com/kdnet/%E8%AE%A4%E8%AF%81%403x.png' alt>
              <div class='nick-name'>
                <div class='name'>
                  <router-link class='name' tag='span' to='/download'>{{ list.author.nickname }}</router-link>
                  <img v-if='list.author.product'
                       src='https://cdn.9kd.com/kdnet/kd-net-VIP.png' />
                </div>
                <div class='time'>{{ list.createTimeStr }}</div>
              </div>
            </div>
            <div class='right'>
              <router-link class='focus-btn' tag='div' to='/download'>关注</router-link>
            </div>
          </div>
          <div class='commun-item-2' @click='goDetail(list.id, list.articleType, list.content)'>{{ list.title }}</div>
          <div class='commun-item-3' @click='goDetail(list.id, list.articleType, list.content)'>
            <div class='first-pic' v-if='list.pictures.length < 3 && list.pictures.length !== 0'>
              <div class='heng' v-if='list.hengPic'
                   :style="{ background: 'url(' + list.pictures[0] + ') no-repeat center center', 'background-size': 'cover' }"></div>
              <div class='shu' v-if='!list.hengPic'
                   :style="{ background: 'url(' + list.pictures[0] + ') no-repeat center center', 'background-size': 'cover' }"></div>
            </div>
            <div class='three-pic' v-if='list.pictures.length >= 3 '>
              <div class='images' v-for='pic in list.pictures.slice(0,3)' :key='pic'
                   :style="{ background: 'url(' + pic + ') no-repeat center center', 'background-size': 'cover' }"></div>
            </div>
          </div>
          <div class='commun-item-4'>
            <router-link class='category-btn' tag='button' to='/download'>{{ list.tagName }}</router-link>
          </div>

          <div class='commun-item-5' v-if='list.hotComment'>
            <div class='commun-item-5-1'>
              <div class='left'>
                <div class='avatar-image'
                     :style="list.hotComment.author.avatar ? { background: 'url(' + list.hotComment.author.avatar + ') no-repeat center center', 'background-size': 'cover' } : {background: 'url(https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png) no-repeat center center', 'background-size': 'cover'}"></div>
                <span class='nick-name'>{{ list.hotComment.author.nickname }}</span>
              </div>
              <div class='right'>
                <span class='iconfont ico'>&#xe69e;</span>
                <span class='hot'>热评</span>
              </div>
            </div>
            <div class='commun-item-5-2' @click='goDetail(list.id, list.articleType, list.content,2)'>
              {{ list.hotComment.content }}
            </div>
          </div>
          <div class='commun-item-6'>
            <div class='commun-item-6-1' @click='getShareInfo(list)'>
              <span class='iconfont ico1'>&#xe690;</span>
              <span class='btn'>分享</span>
            </div>
            <div class='commun-item-6-1' @click='goDetail(list.id, list.articleType, list.content,2)'>
              <span class='iconfont ico2'>&#xe69b;</span>
              <span class='btn'>{{ list.comments > 0 ? list.comments : '评论' }}</span>
            </div>
            <div class='commun-item-6-1' @click="$router.push('/download')">
              <span class='iconfont ico3'>&#xe69c;</span>
              <div class='btn'>{{ list.appreciates > 0 ? list.appreciates : '点赞' }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 弹出层 -->
    <van-share-sheet @select='selectTag' v-model='showShare' :options='options' />
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareInfo: {},
      searchInfo: {
        firstId: 0,
        lastId: 0,
        limit: 10,
        page: 1,
        product: this.id,
        tagId: 0,
        type: 'pgc'
      },
      searchInfo1: {
        firstId: 0,
        lastId: 0,
        limit: 10,
        page: 1,
        product: this.id,
        tagId: 0,
        type: 'pgc'
      },
      lists: [],
      loading: false,
      finished: false,
      isRefreshLoading: false, // 下拉刷新的 loading 状态
      refreshSuccessText: '', // 下拉刷新成功的提示文本
      articles: [],
      flag: true,
      // 分享
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' },
          { name: '微博', icon: 'weibo' }
        ]
      ]
    }
  },
  props: ['id'],
  methods: {
    // 打开分享
    // showShare=true
    getShareInfo(info) {
      this.showShare = true
      this.shareInfo = info
    },
    // 分享
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone()
      } else if (tag.name === '微博') {
        this.weiShare()
      } else {
        this.$toast({
          type: 'html',
          message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">篇文章分享出去</p>'
        })
      }
      this.showShare = false
    },

    // qq分享
    qzone() {
      let url = 'https://' + location.hostname + '/note/' + this.shareInfo.id
      var shareqqzonestring =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url + '&title=' + this.shareInfo.title + '&desc=' + '【分享来之凯迪网@凯迪网】' + '&summary=&site=&pics='
      window.open(shareqqzonestring)
      // window.open(shareqqzonestring, 'newwindow', 'height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
      //此处分享链接内无法携带图片
      // var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      // var url = 'https://tmobile.9kd.com/note/24736'
      // var param = {
      //   url: url || window.location.href,
      //   desc: '天天', /*分享理由*/
      //   title: '哈哈哈' || '', /*分享标题(可选)*/
      //   summary: 'xx',/*分享描述(可选)*/
      //   pics: pic || '',/*分享图片(可选)*/
      //   flash: '', /*视频地址(可选)*/
      //   site: '' /*分享来源 (可选) */
      // };
      // var s = [];
      // for (var i in param) {
      //   s.push(i + '=' + encodeURIComponent(param[i] || ''));
      // }
      // var targetUrl = "http://connect.qq.com/widget/shareqq/iframe_index.html?" + s.join('&');
      // window.open(targetUrl);
    },

    // 微信分享
    weiShare() {
      let url = 'https://' + location.hostname + '/note/' + this.shareInfo.id
      var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=' + '凯迪网' + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + ''
      window.open(sharesinastring)
      // let url = location.href;
      // let url = 'https://3g.163.com/dy/article/FQ3EH4ED0514BE2Q.html?clickfrom=index2018_news_newslist&s=163&w=1&f=wb#offset=4';
      // var sharesinastring =
      //   "http://v.t.sina.com.cn/share/share.php?title=" +
      //   "凯迪网" + this.noteInfo.title +
      //   "&url=" +
      //   url +
      //   "&content=utf-8&sourceUrl=" +
      //   url +
      //   "&pic=" +
      //   "";
      // window.open(
      //   sharesinastring,
      //   "newwindow",
      //   "height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      // );
      // var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      // const share = {
      //   title: '分享标题',
      //   image_url: pic,
      //   share_url: url
      // }
      // window.open(
      //   'https://service.weibo.com/share/share.php?url=' +
      //   encodeURIComponent(share.share_url) +
      //   '&title=' +
      //   share.title +
      //   '&appkey=4129988944&&ralateUid=1744259092&pic=' +
      //   share.image_url +
      //   '&searchPic=true'
      // )

      return

      url = encodeURIComponent(url)
      var param = {
        url: url || window.location.href,
        type: '3',
        count: '1' /** 是否显示分享数，1显示(可选)*/,
        appkey: '4129988944' /** 您申请的应用appkey,显示分享来源(可选)*/,
        title: '你好微博。。。' /** 分享的文字内容(可选，默认为所在页面的title)*/,
        pic: pic || '' /**分享图片的路径(可选)*/,
        ralateUid: '' /**关联用户的UID，分享微博会@该用户(可选)*/,
        rnd: new Date().valueOf()
      }
      var temp = []
      for (var p in param) {
        temp.push(p + '=' + encodeURIComponent(param[p] || ''))
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&')
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400')
    },

    goDetail(id, articleType, content, num = 1) {
      if (articleType === 4) {
        window.open(content)
      } else if (articleType === 5) {
        this.$router.push({
          name: 'special',
          params: {
            id,
            channelId: this.id
          }
        })
      } else {
        if (num === 1) {
          this.$router.push({
            path: '/note/' + id
          })
        } else {
          this.$router.push({
            path: '/note/' + id,
            query: {
              comment: 'comment'
            }
          })
        }
      }
    },
    async onLoad() {
      
      console.log(4444444444);
      let { data: res } = await this.$api.app(this.searchInfo)
      if (res.code === 321) {
        this.finished = true
        return this.$toast(res.msg)
      }
      if (res.code !== 200) return this.$toast.fail(res.msg)
      res.data.list.forEach(item => {
        if (item.pictures.length < 3 && item.pictures.length !== 0) {
          let img = new Image()
          img.src = item.pictures[0]
          img.onload = () => {
            // console.log('width',img.width,'height',img.height)
            // console.log(img.width >= img.height)
            if (img.width >= img.height) {
              item.hengPic = true
            } else {
              item.hengPic = false
            }
          }
        }
      })
      setTimeout(() => {
        this.lists = this.lists.concat(res.data.list)
      })
      this.searchInfo.firstId = res.data.firstId
      this.flag && (this.searchInfo1.firstId = res.data.firstId)
      this.flag = false
      this.searchInfo.lastId = res.data.lastId

      // 加载状态结束
      this.loading = false

      // 数据全部加载完成
      // this.finished = true;

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      /* setTimeout(() => {
        for (let i = 0; i < 15; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 80) {
          this.finished = true;
        }
      }, 1000); */
    },
    // 刷新
    async onRefresh() {
      // 下拉刷新，组件自己就会展示 loading 状态
      // 1. 请求获取数据
      /* const { data } = await getArticles({
        channel_id: this.channel.id, // 频道 ID
        timestamp: Date.now(), // 为了大家方便学习，只要你传递不同的时间戳就一定给你返回不一样的数据，而且数据不为空
        with_top: 1
      }) */

      // 2. 把数据放到数据列表中（往顶部追加）
      // const { results } = data.data
      // this.articles.unshift(...results)
      console.log(123);
      let { data: res } = await this.$api.app(this.searchInfo1)
      if (res.code === 321) {
        // 3. 关闭刷新的状态 loading
        this.isRefreshLoading = false
        this.refreshSuccessText = '当前已是最新内容'
        return
      }
      if (res.code !== 200) return this.$toast.fail(res.msg)
      res.data.list.forEach(item => {
        if (item.pictures.length < 3 && item.pictures.length !== 0) {
          let img = new Image()
          img.src = item.pictures[0]
          img.onload = () => {
            // console.log('width',img.width,'height',img.height)
            // console.log(img.width >= img.height)
            if (img.width >= img.height) {
              item.hengPic = true
            } else {
              item.hengPic = false
            }
          }
        }
      })
      setTimeout(() => {
        this.lists = res.data.list
      })
      this.searchInfo1.firstId = res.data.firstId

      // 3. 关闭刷新的状态 loading
      this.isRefreshLoading = false

      this.refreshSuccessText = res.data.msg
    }
  }
}
</script>

<style lang='less'>
.new-community {
  background: #f5f7f9;
  margin-bottom: 80px;

  .commun-wrap {
    width: 100%;
    min-height: 156px;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 8px;
    background: #ffffff;

    .commun-item-1 {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        position: relative;

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 7px;
        }

        .vip-img {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 20px;
          left: 22px;
        }

        .nick-name {
          display: flex;
          flex-direction: column;

          .name {
            color: #242831;
            font-size: 15px;
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 20px;
              height: auto;
            }
          }

          .time {
            color: #c4c8cf;
            font-size: 12px;
          }
        }
      }

      .right {
        color: #70767d;
        font-size: 22px;

        .focus-btn {
          font-size: 13px;
          color: #F7321C;
          margin-left: 12px;
        }
      }
    }

    .commun-item-2 {
      width: 100%;
      margin: 10px 0;
      font-size: 17px;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .commun-item-3 {
      margin-bottom: 14px;

      .first-pic {
        .heng {
          width: 230px;
          height: 170px;
          border-radius: 4px;
        }

        .shu {
          width: 230px;
          height: 330px;
          border-radius: 4px;
        }
      }

      .three-pic {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .images {
          width: 33%;
          height: 114px;
          border-radius: 4px;
        }

      }

    }

    .commun-item-4 {
      font-size: 0;

      .category-btn {
        width: auto;
        height: 23px;
        padding: 0 10px;
        background: #f5f7f9;
        border-radius: 4px;
        font-size: 12px;
        outline: none;
        border: none;
        margin-bottom: 14px;
      }
    }

    .commun-item-5 {
      width: 350px;
      background: #f5f7f9;
      border-radius: 4px;
      padding: 11px;
      box-sizing: border-box;
      margin-bottom: 14px;

      .commun-item-5-1 {
        width: 100%;
        height: 20px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          .avatar-image {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 4px;
          }

          .nick-name {
            color: #5c5f65;
            font-size: 11px;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .ico {
            color: #fc6e4a;
            margin-right: 4px;
          }

          font-size: 11px;
          color: #fc6e4a;
        }
      }

      .commun-item-5-2 {
        font-size: 12px;
        color: #5c5f65;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .commun-item-6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .commun-item-6-1 {
        display: flex;
        align-items: center;
        color: #70767d;
      }

      .ico1 {
        font-size: 15px;
        margin-right: 3px;
      }

      .ico2 {
        font-size: 14px;
        margin-right: 2px;
      }

      .ico3 {
        font-size: 15px;
      }
    }
  }
}
</style>
