<template>
  <div class="video-list-fall">
    <div class="list-fall-item">
      <video-fall-item
        v-for="item in leftData" 
        :key="item.id" 
        :item="item"
        @toDetail="viewDetail"
      />
    </div>
    <div class="list-fall-item">
      <video-fall-item
        v-for="item in rightData" 
        :key="item.id" 
        :item="item"
        @toDetail="viewDetail"
      />
    </div>
  </div>
  
</template>

<script>
var leftData = [],
  rightData = [],
  leftHeight = 0,
  rightHeight = 0;

import VideoFallItem from "./VideoFallItem.vue";
export default {
  name: 'VideoWaterfall',
  components: {
    VideoFallItem
  },
  props:{
    lists:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      leftData: [],
      rightData: [],
      leftHeight: 0,
      rightHeight: 0,
      width: document.getElementById('app').clientWidth,
      imgWidth: 230
    }
  },
  mounted() {
    this.imgWidth = (this.width - (this.width * 0.06)) * 0.49
  },
  methods:{
    branchData(isPull, listData) {
        if (isPull) {
          leftData = [],
          rightData = [],
          leftHeight = 0,
          rightHeight = 0;
        }
        for (let i = 0, len = listData.length; i < len; i++) {
          // let ratio = listData[i].width / this.imgWidth
          // let currentItemHeight = listData[i].height / (ratio > 0 ? ratio : 1);
          let currentItemHeight = listData[i].height;
          let textHeight = listData[i].title.length > 12 ? 40 : 20;
          if(leftHeight <= rightHeight){
            leftData.push(listData[i]);
            leftHeight += (currentItemHeight + textHeight);
          } else {
            rightData.push(listData[i]);
            rightHeight += (currentItemHeight + textHeight);
          }
        }
        this.leftData = leftData;
        this.rightData = rightData;
    },
    viewDetail(id, articleType, content) {
      this.$emit('toDetail', id, articleType, content)
    },
  },
  
}
</script>

<style lang="less" scoped>
.video-list-fall{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 3%;
  .list-fall-item{
    width: 49%;
  }
}
</style>