<template>
    <div class="hot-list">
      <div class='news-head'>
        <van-nav-bar @click-left='onClickLeft' @click-right='onClickRight'>
          <template #left>
            <div>
              <img class="nav-left-icon" :src="$baseInfo.LOGO_IMG"/>
              <span class="nav-left-title">凯迪网首页</span>
            </div>
          </template>
          <template #right>
            <div><span class="nav-right-button">打开</span></div>
          </template>
        </van-nav-bar>
      </div>
      <div class="hot-sel">
        <img class="hot-bg" src="https://cdn.9kd.com/kdnet/4344a60f60314a68a0783cb767029f40.png"/>
        <div :class="[selIndex === 0 ? 'text-sel' : 'text', 'hot-sel-left']" @click="selIndex=0"><img :src="selIndex === 0 ? newImgSel : newImg"/><span :class="[selIndex === 0 ? 'text-sel' : 'text']">24h热闻</span></div>
        <div :class="[selIndex === 1 ? 'text-sel' : 'text', 'hot-sel-right']" @click="selIndex=1"><img :src="selIndex === 1 ? capImgSel : capImg"/><span >今日热帖</span></div>
      </div>

      <!-- 24h热闻 -->
      <van-pull-refresh v-model="isNewLoading" :success-text="refreshSuccessText" :success-duration="1500" @refresh="onRefreshNew" v-if="selIndex===0" style="margin-bottom: 1.89333rem;">
        <div class="hot-new-root" v-for="(item, i) in hotNews" :key="item.title+i" @click="queryone(item)">
          <div class="line-item">
            <img class="tag" :src="tagsObj[i]" v-if="i < 3"/>
            <span class="tag-text" v-if="i >= 3 && i < 9">{{'0' + (i + 1)}}</span>
            <span class="tag-text" v-if="i >= 9">{{i + 1}}</span>
            <div class="middle" :style="{ height: item.firstPicture? '106px' : 'max-content' }">
              <span class="item-title">{{item.title}}</span>
              <span class="source">{{item.author.nickname}}<font class="hot">{{item.views > 9500 ? `${Math.round(item.views/10000)}万热度` : (item.views > 950 ? `${Math.round(item.views/1000)}千热度` : `${Math.round(item.views/100)}百热度`) }}</font></span>
            </div>
            <img class="picture" :src="item.firstPicture" v-if="item.firstPicture"/>
          </div>
          <div class="line-interval"></div>
        </div>
      </van-pull-refresh>

      <!-- 今日热帖 -->
      <van-pull-refresh v-model="isPostLoading" :success-text="refreshSuccessText" :success-duration="1500" @refresh="onRefreshPost" v-if="selIndex===1"  style="margin-bottom: 1.89333rem;">
        <div class="hot-Post-root" v-for="(item, i) in hotPosts" :key="item.title+i" @click="queryone(item)">
          <div class="line-item">
            <img class="tag" :src="tagsObj[i]" v-if="i < 3"/>
            <span class="tag-text" v-if="i >= 3 && i < 9">{{'0' + (i + 1)}}</span>
            <span class="tag-text" v-if="i >= 9">{{i + 1}}</span>
            <div class="middle" :style="{ height: item.firstPicture? '106px' : 'max-content' }">
              <span class="item-title">{{item.title}}</span>
              <span class="source"><img class="head" :src="item.author.avatar ? item.author.avatar : 'https://cdn.9kd.com/kdnet/f34dabc7109b4dfc9f967708d5befa2f.png'"/><font>{{item.views > 9500 ? `${Math.round(item.views/10000)}万热度` : (item.views > 950 ? `${Math.round(item.views/1000)}千热度` : `${Math.round(item.views/100)}百热度`) }}</font></span>
            </div>
           </div>
          <div class="line-interval"></div>
        </div>
      </van-pull-refresh>
    </div>
</template>

<script>
  import Bus from '../../utils/bus';
  export default {
    name: "HotList",
    data() {
      return {
        options: [[{ name: '微信', icon: 'wechat' }, { name: 'QQ', icon: 'qq' }]],
        selIndex: 0,
        newImgSel: 'https://cdn.9kd.com/kdnet/38bee123c67843fa8bd9b121ca451ec4.png',
        newImg: 'https://cdn.9kd.com/kdnet/1f475545c967404dac35a100b68393d0.png',
        capImgSel: 'https://cdn.9kd.com/kdnet/cba4116efc2d45ca9514ff03c7d28cf4.png',
        capImg: 'https://cdn.9kd.com/kdnet/2a1b1833f17f4b25bf6931fe7f681dbe.png',
        isNewLoading: false,
        isPostLoading: false,
        refreshSuccessText: '当前已是最新内容',
        hotNews: [],
        tagsObj: {
          0: 'https://cdn.9kd.com/kdnet/9458020e376a4ed798cad9521eb560f6.png',
          1: 'https://cdn.9kd.com/kdnet/89b035dc2665417c8b1b9b662ffa7829.png',
          2: 'https://cdn.9kd.com/kdnet/aa171aa1e02747e79969e9f1435bec3d.png'
        },
        hotPosts: [{ name: '1'}, {name: '2'}, {name: '3'}, {name: '4'},{name:'5'},{name:'6'},{name:'7'}],
        selItem: {}
      }
    },
    created() {
      Bus.$emit(Bus.SHOW_DOWN_FLOATING, false);
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, true);
    },
    mounted() {
      this.onRefreshNew();
      this.onRefreshPost();
    },
    methods: {
      /**返回首页*/
      onClickLeft() {
        Bus.$emit(Bus.SHOW_LOOP_FLOATING, false);
        this.$router.push({
          path: '/topic'
        })
      },
      //打开凯迪App
      onClickRight() {
        Bus.$emit(Bus.SHOW_OPEN_DIALOG, {
          show: true, noteInfo: null,
          continuteType: this.selIndex === 0 ? Bus.CONTINUTETYPE_HOT_NEW : Bus.CONTINUTETYPE_HOT_POST
        });
      },
      // 查询文章详情
      queryone(noteInfo) {
        this.$api.queryone(noteInfo.id).then(res => {
          let data = res.data;
          if (data.code !== 200) {
            this.$notify({type: 'primary', message: '文章查询失败'});
            return
          }
          if (data.data.id === noteInfo.id) {
            this.showOpenSelDialog(data.data);
          }
        });
      },
      // 进入文章详情
      goDetail(id, articleType, content) {
        if (articleType === 4) {
          window.open(content)
        } else if (articleType === 5) {
          this.$router.push({
            name: 'special',
            params: {
              id, channelId: this.id,
            },
          })
        } else {
          this.$router.push({
            path: '/note/' + id,
          })
        }
      },
      // 打开App选择器
      showOpenSelDialog(noteInfo) {
        this.selItem = noteInfo;
        Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo: noteInfo, continuteType: Bus.CONTINUTETYPE_ARTICLE_DETAIL });
      },
      /**查询24h热闻*/
      onRefreshNew() {
        this.isNewLoading = true;
        this.$api.queryHotNews(50).
        then(res => {
          this.isNewLoading = false;
          let data = res.data;
          if (!data || data.code !== 200) { return; }
          this.hotNews = data.data;
        })
      },
      /**查询今日热帖*/
      onRefreshPost() {
        this.isPostLoading = true;
        this.$api.queryHotPosts().
        then(res => {
          this.isPostLoading = false;
          let data = res.data;
          if (!data || data.code !== 200) { return; }
          this.hotPosts = data.data;
        })
      }
    }
  }
</script>

<style lang='less'>
  .hot-list {
    .nav-left-icon {
      width: 39px;
      height: 39px;
      vertical-align: middle;
    }
    .nav-left-title {
      padding-left: 7px;
      font-size: 13px;
      color: #3A3E45;
      vertical-align: middle;
      font-weight: bold;
    }
    .nav-right-button {
      width: 60px;
      height: 30px;
      line-height: 30px;
      color: white;
      font-size: 11px;
      text-align: center;
      background: #F7321C;
      border-radius: 4px;
      vertical-align:middle;
      display: inline-block;
    }
    .hot-sel {
      height: max-content;
      position: relative;
      margin-bottom: 34px;
      .hot-bg {
        width: 100%;
        height: 160px;
        margin: 0;
      }
      .hot-sel-left {
        width: 50%;
        height: 47px;
        left: 0;
        top: 148px;
        text-align: center;
        border-top-left-radius: 10px;
        position: absolute;
      }
      .hot-sel-right {
        width: 50%;
        height: 47px;
        left: 50%;
        top: 148px;
        text-align: center;
        border-top-right-radius: 10px;
        position: absolute;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 7px;
        vertical-align: middle;
      }
      span {
        height: 47px;
        line-height: 47px;
        vertical-align: middle;
      }
      .text-sel {
        color: #7C8493;
        font-size: 15px;
        background: white;
      }
      .text {
        color: #333333;
        font-size: 15px;
        background: #f3f6f9;
      }
    }
  }
  .hot-new-root {
    height: max-content;
    .line-item {
      max-height: 105px;
      display: flex;
    }
    .tag {
      width: 24px;
      height: 24px;
      margin-left: 11px;
      margin-top: 15px;
    }
    .tag-text {
      width: 24px;
      height: 24px;
      margin-left: 14px;
      margin-top: 15px;
      color: #9DA4B1;
      font-size: 17px;
    }
    .middle {
      height: max-content;
      flex: 1;
      display: flex;
      flex-direction: column;
      .item-title {
        flex: 1;
        line-height: 18px;
        margin-right: 15px;
        padding-top: 15px;
        padding-bottom: 10px;
        font-size: 15px;
        color: #111111;
        word-break: break-all;
        display: inline-block;
      }
      .source {
        height: 16px;
        margin: unset;
        padding: unset;
        padding-bottom: 11px;
        font-size: 11px;
        color: #8C94A3;
        display: inline-block;
      }
      .hot {
        padding-left: 6px;
      }
    }
    .picture {
      width: 114px;
      height: 78px;
      margin-right: 15px;
      margin-top: 15px;
      border-radius: 4px;
      object-fit: cover;
    }
    .line-interval {
      width: 87%;
      height: 1px;
      margin-left: 35px;
      margin-right: 15px;
      background: #F3F6F7;
    }
  }
  .hot-Post-root {
    height: max-content;
    .line-item {
      max-height: 105px;
      display: flex;
    }
    .tag {
      width: 24px;
      height: 24px;
      margin-left: 11px;
      margin-top: 15px;
    }
    .tag-text {
      width: 24px;
      height: 24px;
      margin-left: 14px;
      margin-top: 15px;
      color: #9DA4B1;
      font-size: 17px;
    }
    .middle {
      height: 105px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .item-title {
        flex: 1;
        line-height: 18px;
        margin-right: 15px;
        padding-top: 15px;
        padding-bottom: 10px;
        font-size: 15px;
        color: #111111;
        word-break: break-all;
        display: inline-block;
      }
      .source {
        height: 20px;
        line-height: 20px;
        margin: unset;
        padding: unset;
        padding-bottom: 11px;
        font-size: 15px;
        color: #242831;
        display: inline-block;
        vertical-align: middle;
        .head {
          width: 20px;
          height: 20px;
          margin-right: 7px;
          border-radius: 10px;
          vertical-align: middle;
          object-fit: cover;
        }
      }
      .hot {
        padding-left: 4px;
        color: #8C94A3;
        font-size: 11px;
        vertical-align: middle;
      }
    }
    .line-interval {
      width: 87%;
      height: 1px;
      margin-left: 35px;
      margin-right: 15px;
      background: #F3F6F7;
    }
  }
</style>
