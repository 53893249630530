<template>
  <div class="home-container">
    <van-nav-bar id="tool_bar_height">
      <template #title>
        <div class="iconfont log">&#xe682;</div>
      </template>
    </van-nav-bar>
    <van-sticky @scroll="scrollHandle" :class="[{'van-sticky-fixed': $Is_PC}]">
      <div class="tabs-icon" ref="tabsIcon">
        <router-link to="/nav" class="iconfont" :class="[nav ? 'nav' : 'nav1']">&#xe686;</router-link>
      </div>
      <van-tabs
        swipeable
        @click="tabschange"
        v-model="active"
        class="vanTabs"
        line-width="42px"
        @rendered="tabRendered"
        :background="tabsBackground"
        :color="tabsColor"
        :title-active-color="titleActiveColor"
        :title-inactive-color="titleInactiveColor"
      >
        <van-tab :name="channel.id" :title="channel.name" v-for="channel in channels" :key="channel.id">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <!--  非社区  -->
    <Artical v-if="id === active && isTopic === 1 && !isBrandIndex && !isDynamic" :id="active"></Artical>
    <!--  社区  -->
    <Topic v-if="isTopic === 2 && !isBrandIndex && !isDynamic" :id="active"></Topic>

    <BrandIndex v-if="isBrandIndex" />

    <Dynamic v-if="isDynamic" />
    <!-- 首页广告位 -->
    <div class="adv-home-fixed" v-if="advShow && advFixedContent" @click="openAdvLink(advFixedContent.adUrl)">
      <div class="adv-img-container">
        <img class="adv-img" :src="advFixedContent.pictures[0] ? advFixedContent.pictures[0] : ''" @error="closeAdv()" alt="">
      </div>
      <div class="adv-close" @click.stop="closeAdv()">
        <img src="http://cdn.9kd.com/kdnet/wap_adv_close.png" alt="">
      </div>
    </div>

    <!-- 浮标广告有机牛肉转盘 -->
    <div class="turnable-buoy-box" v-if="turnableBuoyShow">
      <div class="mask"></div>
      <div class="turnable-buoy-upper" v-if="turnableShow">
        <div class="turnable-buoy-tilte">
          <img src="http://cdn.9kd.com/kdnet/turnable_buoy_advertisement_title.png" alt="">
        </div>
        <div class="turnable-buoy-main-box">
          <div class="turnable-buoy-bg">
            <div class="count-down">{{turnableTime}}秒后自动抽奖</div>
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_advertisement_bg.png" alt="">
          </div>
          <div class="turnable-buoy-main">
            <img  ref="turnable" src="http://cdn.9kd.com/kdnet/turnable_buoy_advertisement_main.png" alt="">
          </div>
          <div class="turnable-buoy-btn" @click.stop="run" ref="turnable-btn">
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_advertisement_btn.png" alt="">
          </div>
        </div>
        <div class="turnable-buoy-close" @click="closeTurnableBuoy">
          <img src="http://cdn.9kd.com/kdnet/turnable_buoy_close.png" alt="">
        </div>
      </div>
      <!-- 成功 -->
      <div class="turnable-buoy-success" v-if="successShow">
       <div class="turnable-buoy-success-box">
          <div class="title">
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_success_title.png" alt="">
          </div>
          <div class="main">
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_main_cattle.png" alt="">
          </div>
          <div class="btn" @click="downloadApp">
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_btn_cattle.png" alt="">
          </div>
          <div class="tip">安卓用户下载凯迪网APP尝鲜体验</div>
          <div class="turnable-buoy-close" @click="closeTurnableBuoy">
            <img src="http://cdn.9kd.com/kdnet/turnable_buoy_close.png" alt="">
          </div>
        </div>
       </div>
    </div>

  </div>
</template>

<script>
import Artical from './Artical'
import Topic from '../topic'
import Base64 from '../../utils/base64'
import BrandIndex from '../brand/ranking'
import Dynamic from '@/views/chamber/dynamic'

export default {
  data() {
    return {
      turnableTimer: null,
      turnableTime: 5,
      turnableBuoyShow: false,
      turnableShow: true,
      successShow: false,
      active: this.$store.state.channelID,
      id: this.$store.state.channelID,
      tabsColor: '#ffffff',
      tabsBackground: '#ffffff',
      titleActiveColor: '#F7321C',
      titleInactiveColor: '#1c1e17',
      isLoading: false,
      count: 0,
      channels: [],
      nav: false,
      isTopic: 3,
      advShow: true,
      advFixedContent:null,
      isBrandIndex: false,
      isDynamic: false,
    }
  },
  beforeCreate() {
    this.$route.path === '/freeport' && this.$store.commit('increment', 2)
  },
  created() {
    this.queryChannel();
    this.queryAdvDetail()
    // 变化为默认的样式
    this.$store.commit('changeDownloadCss',true)
  },
  computed: {
    channelID() {
      return this.$store.state.channelID
    }
  },
  watch: {
    active(newz, oldz) {
      this.$store.commit('increment', newz)
    },
    channelID(newz, oldz) {
      this.setActive(newz, oldz)
    }
  },
  components: {
    Artical,
    Topic,
    BrandIndex,
    Dynamic
  },
  beforeDestroy(){
    clearInterval(this.turnableTimer)
  },
  methods: {
    setActive(newz, oldz) {
      this.active = newz
      setTimeout(() => {
        this.id = newz
      });
    },
    tabRendered(id, title) {
    },
    async downloadApp() {
      window._hmt = window._hmt || []
      window._hmt.push(['_trackEvent', 'WAP点击下载', '点击', '点击下载领牛肉'])
      let u = navigator.userAgent
      // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (!isiOS) {
        //请求接口1  /kd-admin/admin/AppUpdate/getUpdateInfo
        // window.location.href = res.data.url
        //请求接口2  /kd-ucenter/ucenter/statistic/countQRCodeDownload
        let { data: res } = await this.$api.getUpdateInfo()
        if (res.code !== 200) return this.$toast.fail('链接错误')
        // 正式环境分包
        if (window.location.origin.indexOf('m.9kd') >= 0) {
          var hash = location.hash.replace('#', '')
          if (hash) {
            var url = res.data.url
            var index = url.lastIndexOf('/')
            var hashUrl = url.substring(0, index + 1)
            hashUrl = hashUrl + 'kdnet_' + hash + '_4.3.0_build_206.apk'
            res.data.url = hashUrl
          }
        }
        window.location.href = res.data.url
        await this.$api.countQRCodeDownload()
      } else {
        window.location.href = 'https://apps.apple.com/cn/app/%E5%87%AF%E8%BF%AA%E8%B5%84%E8%AE%AF/id1533642383'
      }
    },
    run(){
      window._hmt = window._hmt || []
      window._hmt.push(['_trackEvent', 'WAP抽奖数', '点击', '点击牛肉转盘'])
      this.rotate(1819)
    },
    // 打开转盘
    openTurnableBuoy(){
      this.turnableBuoyShow = true
      this.advShow = false
      this.turnableTimer = setInterval(()=>{
        this.turnableTime--;
        if(this.turnableTime == 0){
          clearInterval(this.turnableTimer)
          window._hmt = window._hmt || []
          window._hmt.push(['_trackEvent', 'WAP抽奖数', '点击', '点击牛肉转盘'])
          this.rotate(1819)
        }
      },1000)
    },
    // 关闭转盘
    closeTurnableBuoy(){
      this.turnableBuoyShow = false
      clearInterval(this.turnableTimer)
      this.turnableShow = true
      this.successShow = false
    },
    //转盘复原
    reback() {
      let tur = this.$refs.turnable
      tur.style.transition = 'transform 0s'
      tur.style.transform = 'rotate(-9deg)'
    },
    //旋转
    rotate(angle) {
      clearInterval(this.turnableTimer)
      this.$refs['turnable-btn'].style.pointerEvents = 'none';
      let tur = this.$refs.turnable
      tur.style.transition = 'transform 3s'
      tur.style.transform = 'rotate(' + angle + 'deg)'
      setTimeout(()=>{
        this.turnableShow = false;
        this.successShow = true;
        this.reback()
      },4500)
    },
    // 改变tabs
    tabschange(val, title) {

      this.channels.forEach(item => {
        if (item.id === val) {
          if(val === 'brand-index'){
            this.isBrandIndex = true
            this.isDynamic = false
          } else if (val === 'chamber-dynamic') {
            this.isDynamic = true
            this.isBrandIndex = false
          }else if (item.type === 2) {
            // 说明是社区
            this.isTopic = 2
            this.isBrandIndex = false
            this.isDynamic = false
          } else {
            this.isTopic = 1
            this.isBrandIndex = false
            this.isDynamic = false
          }
        }
      })
      this.id = 999
      setTimeout(() => {
        this.id = val
      });
    },
    //查询所有频道
    queryChannel() {
      this.$api.queryChannel(0).then(res => {
        let data = res.data;
        if (data.code !== 200) return this.$toast.fail(data.msg);
        data.data.unshift({ id: 0, name: '推荐' },{ id: 'brand-index', name: '品牌指数' })
        this.channels = data.data
        this.channels.splice(2,0,{ id: '00', name: '热榜' })
        this.channels.splice(2,0,{ id: 'chamber-dynamic', name: '商协会动态' })
        if (this.$route.path.indexOf('topic') !== -1) {
          for (let i = 0; i < this.channels.length; i++) {
            if (this.channels[i].type === 5) {
              this.active = this.channels[i].id
              this.isTopic = 2
              return false
            }
          }
        }
        for (let i = 0; i < this.channels.length; i++) {
          if (this.channels[i].id === 10079) {
            this.channels.splice(i,1)
          }
          if (this.channels[i].id === this.active) {
            if (this.channels[i].type === 2) {
              // 说明是活动
              this.isTopic = 2
              // return false
            } else {
              this.isTopic = 1
              // return false
            }
          }
        }
      })
    },
    // 凯迪网的样式
    scrollHandle(data) {
      if (!data.isFixed) {
        this.tabsColor = '#F7321C'
        this.tabsBackground = '#ffffff'
        this.titleActiveColor = '#F7321C'
        this.titleInactiveColor = '#1c1e17'
        this.$refs.tabsIcon.style.background = '#ffffff'
        this.nav = false
      } else {
        this.nav = true
        this.tabsColor = '#ffffff'
        this.tabsBackground = '#F7321C'
        this.titleActiveColor = '#ffffff'
        this.titleInactiveColor = '#ffffff'
        this.$refs.tabsIcon.style.background = '#F7321C'
      }
    },
    openAdvLink(url){
      let code = url.split('redirectUrl=')[1]
      if (code && Base64.decode(code).indexOf('turnable') !== -1) {
        this.$router.push('/turnable')
      } else {
        window.open(url)
      }
    },
    closeAdv(){
      this.advShow = false;
    },
    queryAdvDetail(){
      this.$api.queryAdvContent({
        "articleId": 0,
        "extend": "",
        "product": 0,
        "system": "",
        "type": "WAP1",
        "unicode": "",
        "version": ""
      }).then(res => {
        if(res.data.code == 200 && res.data.data){
          this.advFixedContent = res.data.data;
          this.advExposureAdd(this.advFixedContent)
        }else{
          this.advFixedContent = null;
        }

      }).catch(error=>{
        console.log(error)
      })
    },
    advExposureAdd(item){
      this.$api.queryAdvAddOne({adId:item.id}).then(res=>{}).catch(err=>{})
    }

  },
}
</script>
<style lang="less">
.van-tab {
  font-size: 16px;
}
.van-tabs__wrap {
  padding-right: 50px;
}
.van-tabs--line {
  border-bottom: 1px solid #f6f6f6;
}
.home-container {
  .van-nav-bar {
    background: #F7321C;
    .log {
      color: #ffffff;
      font-size: 45px;
      margin-top: 7px;
    }
    .ico {
      color: #ffffff;
      font-size: 24px;
    }
  }
  .tabs-icon {
    width: 50px;
    height: 44px;
    line-height: 33px;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 2;
    .nav {
      color: #fff;
    }
    .nav1 {
      color: #000;
    }
  }
  .new-year-theme{
    background: #F7321C;
    overflow: hidden;
    .van-nav-bar__right,.van-nav-bar__left{
      padding: 0;
    }
    .new-year-left{
      height: 100%;
      position: relative;
      img{
        height: 80%;
        display: block;
        position: absolute;
        left: -0.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .new-year-right{
      height: 100%;
      position: relative;
      img{
        height: 100%;
        margin-right: -0.4rem;
        display: block;
      }
    }
    .van-nav-bar__title{
      width: 67%;
      max-width: 67%;
      position: relative;
      .new-year-title-bg{
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        img{
          width: 100%;
        }
      }
    }

  }
  .adv-home-fixed{
    position: fixed;
    bottom: 112px;
    right: 25px;
    overflow: hidden;
    width: 66px;
    height: 66px;
    border-radius: 6px;
    .adv-img-container{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .adv-img{
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    .adv-close{
      position: absolute;
      right: -1px;
      top: -1px;
      width: 20%;
      img{
        display: block;
        width: 100%;
      }
    }

  }
  .disable {
  	pointer-events: none;
  }
  .turnable-buoy-box{
    width: 100%;
    height: 100%;
    position: relative;
    .mask{
      z-index: 99;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
    .turnable-buoy-upper{
      z-index: 100;
      position: fixed;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      .turnable-buoy-tilte{
        width: 350px;
        margin: auto;
        img{
          width: 100%;
        }
      }
      .turnable-buoy-main-box{
        position: relative;
        width: 100%;
        height: calc(54vh);
        margin: auto;
        .turnable-buoy-bg{
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -161px;
          margin-left: -174px;
          width: 350px;
          img{
            width: 100%;
          }
        }
        .turnable-buoy-main{
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -117.5px;
          margin-left: -117.5px;
          width: 235px;
          transform: rotate(-9deg);
          img{
            width: 100%;
          }
        }
        .count-down{
          position: absolute;
          left: 50%;
          bottom: 20px;
          width: 100px;
          margin-left: -50px;
          font-size: 12px;
          color: #ffac9b;
          text-align: center;
        }
        .turnable-buoy-btn{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 91px;
          img{
            width: 100%;
          }
          transition: transform 5s;
          -o-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          -moz-transform-origin: 50% 50%;
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
        }
      }
      .turnable-buoy-close{
        width: 24px;
        position: absolute;
        bottom: -65px;
        left: 50%;
        margin-left: -12px;
        img{
          width: 100%;
        }
      }
    }
    .turnable-buoy-success{
      z-index: 100;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      .turnable-buoy-success-box{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img{
          width: 100%;
      }
      .title{
        // position: absolute;
        // top: 0;
        // left: 50%;
        // margin-left: -138px;
        // margin-top: 17.7%;
        width: 275.5px;
      }
      .main{
        width: 234px;
      }
      .btn{
        width: 239px;
        margin-top: -18px;
      }
      .tip{
        font-size: 12px;
        text-align: center;
        color: #fff;
        opacity: .6;
      }
      .turnable-buoy-close{
        width: 24px;
        margin-top: 18px;
      }
    }
  }
  .van-sticky-fixed .van-sticky{
        position: relative;
        width: 440px;
    }
    .van-sticky-fixed .van-sticky--fixed{
        position: fixed;
    }
}
.van-sticky--fixed{
  background: #F7321C;
}
</style>
