<template>
  <div class="common-card" @click="viewDetail(item.id, item.articleType, item.content)">
    <div class="spec-block-2">
      <div class="video-img-box">
        <img :src="item.firstPicture" alt="">
        <div class="video-play-icon">
            <span class="iconfont">&#xe6a4;</span>
        </div>
        <div class="video-views-time">
          <div class="video-time">
            <span class="iconfont">&#xe64a;</span>
            {{ item.views }}
          </div>
          <div class="video-time">
            {{ item.time | videoDealTime }}
          </div>
        </div>
      </div>
      <div class="title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoFallItem',
  props:{
    item:{
      type: Object,
      default: () => {}
    }
  },
  methods:{
    viewDetail(id, articleType, content) {
      this.$emit('toDetail', id, articleType, content)
    },
  }
}
</script>

<style lang="less" scoped>
  .common-card{
      margin-bottom: 20px;
    }
    .video-img-box{
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      .video-play-icon{
        position: absolute;
        right: 8px;
        top: 8px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        color: white;
        line-height: 18px;
        text-align: center;
        .iconfont{
          font-size: 9px;
          transform: scale(0.75);
          display: block;
        }
      }
      .video-views-time{
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
        padding: 0 10px;
        box-sizing: border-box;
        line-height: 38px;
        color: white;
        font-size: 13px;
        background: linear-gradient(to bottom ,rgba(0,0,0,0),rgba(0,0,0,0.4));
        .iconfont{
          font-size: 10px;
        }
      }
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .title{
      padding: 0 2px;
      margin-top: 8px;
      font-size: 15px;
      color: #222222;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
</style>