<template>
  <div class="inWrap">
    <div class="common-card">
      <!-- 一图显示 -->
      <div v-if="list.coverImgList.length < 3 && list.coverImgList.length !== 0" class="spec-block-1" @click="viewDetail(list.articleId)">
        <div class="one-picture">
          <div class="one-picture-left">
            <div class="title">{{ list.title }}</div>
            <div class="spec">
              <div class="chamber-name">{{ list.chamberName }}</div>
              <div class="author">{{ list.publishTs | compareDate }}</div>
            </div>
          </div>
          <div class="picture" v-if='list.coverImgList && list.coverImgList.length' :style="{ background: 'url(' + list.coverImgList[0] + ') no-repeat center center', 'background-size': 'cover' }"></div>
        </div>
      </div>
      <!-- 无图/三图/大图显示 -->
      <div class="spec-block-2" v-if="list.coverImgList.length >= 3 || list.coverImgList.length === 0" @click="viewDetail(list.articleId)">
        <div class="title">{{ list.title }}</div>
        <div class="three" v-if="list.coverImgList.length >= 3">
          <img
            class="three-pic"
            v-for="(item, index) in list.coverImgList"
            style='object-fit: cover'
            :key="item.id"
            :src="item"
            alt=''
            @error="onError(index)"
          />
        </div>
        <div class="spec">
          <div class="chamber-name">{{ list.chamberName }}</div>
          <div class="author">{{ list.publishTs | compareDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: ['list','index'],
  filters: {
  },
  methods: {
    viewDetail(id) {
      this.$emit('toDetail', id)
    },
    onError(index) {
      this.$set(this.list.coverImgList, index, 'https://ysh-cdn.kaidicloud.com/test/kd/2024/01/31/7d684a84267847b28444c6e60396a1f8.png')
    }
  },
}
</script>

<style scoped>
.ico {
  font-size: 14.8px;
  color: #F7321C;
  margin-right: 3px;
  line-height: 1;
}
</style>
<style lang="less">
.inWrap {

  .common-card {
    .spec-block-1,
    .spec-block-2 {
      border-bottom: 1px solid #f6f6f6;
      padding: 13px;
    }
    .title {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #242831;
      .p1 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .p2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: #70767d;
        max-height: 60px;
        font-size: 14px;
        margin-top: 5px;
      }
    }
    .spec {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      color: #8c94a3;
      margin-top: 12px;
      /* .spec-ico {
      margin-right: 5px;
      .ico {
        font-size: 12px;
        color: #F7321C;
      }
    } */
      .author {
        flex-shrink: 0;
        margin-left: 8px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        color: #8c94a3;
      }
    }
    .spec-block-1 {
      .one-picture {
        display: flex;
        justify-content: space-between;
        .one-picture-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: 102px;
          .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            word-break: break-all;// 纯数字不换行
          }
        }
        .picture {
          flex-shrink: 0;
          width: 114px;
          height: 78px;
          margin-left: 20px;
          border-radius: 4px;
        }
      }
    }
    .chamber-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;// 纯数字不换行
    }
    .spec-block-2 {
      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .banner {
        width: 100%;
        height: 195px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        margin-top: 13px;
      }
      .three {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 78px;
        overflow: hidden;
        margin-top: 10px;
        border-radius: 4px;
        .three-pic {
          margin-right: 1px;
          flex-shrink: 0;
          width: 33.3%;
          height: 78px;
        }
      }
    }
  }
}
</style>
